import { useState } from "react";
import "./header.css";
import { Link } from "react-scroll";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="webpage-header">
      <Link
        className="webpage-header-left"
        activeClass="active"
        to="intro"
        spy={true}
        smooth={true}
        offset={-50}
        duration={500}
      >
        neonimble.ai
      </Link>
      <div className="webpage-header-right">
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          &#9776;
        </div>
        <div
          className={`header-menu ${
            isMobileMenuOpen ? "mobile-menu-open" : ""
          }`}
        >
          <Link
            className="header-menu-item"
            activeClass="active"
            to="intro"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            onClick={closeMobileMenu}
          >
            Home
          </Link>
          <Link
            className="header-menu-item"
            activeClass="active"
            to="articles"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            onClick={closeMobileMenu}
          >
            Why
          </Link>
          <Link
            className="header-menu-item"
            activeClass="active"
            to="vision"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            onClick={closeMobileMenu}
          >
            What
          </Link>
          <Link
            className="header-menu-item"
            activeClass="active"
            to="solutions"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            onClick={closeMobileMenu}
          >
            How
          </Link>
          <Link
            className="header-menu-item"
            activeClass="active"
            to="contact"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            onClick={closeMobileMenu}
          >
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
