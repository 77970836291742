import React, { useState, useEffect } from 'react';
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { Routes, Route, useLocation } from "react-router-dom";
import moonbaseLightTheme from "./lightTheme/LightTheme";

import Dashboard from "./dashboard/Dashboard";
import Account from "./account/Account";
import ProgressReport from "./progressreport/ProgressReport";
import Sidebar from "./sidebar/Sidebar";
import "./NeoNimble.css";
import Header from "./header/Header";
// import WebPage from './webpage/WebPage';

import ExercisePage from './exercisepage/ExercisePage';
import Test from "./test/Test";
import ExerciseFeed from './exercisefeed/ExerciseFeed';
import Wired from './test/wiredskull/wired'
import WebPage from './webpage/WebPage';


function NeoNimble() {
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [isHeaderVisible, setHeaderVisible] = useState(true);

  const location = useLocation();

  useEffect(() => {
    const currentRoute = location.pathname;
    const sidebarVisibleRoutes = [
      "/Dashboard", "/Account", "/ProgressReport", "/standingwallslide",
      "/standingextension", "/flexiononawall", "/squat",
      "/quadrupedrockback", "/standingkneeflexion"
    ];
    if (sidebarVisibleRoutes.includes(currentRoute)) {
      setSidebarVisible(true);
    } else {
      setSidebarVisible(false);
    }

    if (currentRoute === "/") {
      setHeaderVisible(false);
    } else {
      setHeaderVisible(true);
    }
  }, [location]);

  return (
    <ThemeProvider theme={moonbaseLightTheme}>
      <StyledEngineProvider injectFirst>
        {isSidebarVisible && <Sidebar />}
        {isHeaderVisible && <Header />}
        <div className="mainDiv">
          <Routes>
            <Route path="Dashboard" element={<Dashboard />} />
            <Route path="*" element={<WebPage />} />
            <Route path="Account" element={<Account />} />
            <Route path="ProgressReport" element={<ProgressReport />} />
            <Route path="Wired" element={<Wired />} />

            <Route path="standingwallslide" element={<ExercisePage exerciseType="standingwallslide" />} />
            <Route path="standingextension" element={<ExercisePage exerciseType="standingextension" />} />
            <Route path="flexiononawall" element={<ExercisePage exerciseType="flexiononawall" />} />
            <Route path="quadrupedrockback" element={<ExercisePage exerciseType="quadrupedrockback" />} />
            <Route path="squat" element={<ExercisePage exerciseType="squat" />} />
            <Route path="standingkneeflexion" element={<ExercisePage exerciseType="standingkneeflexion" />} />

            <Route path="Test" element={<Test />} />

            <Route path="standingwallslide_feed" element={<ExerciseFeed exerciseType="standingwallslide" />} />
            <Route path="standingextension_feed" element={<ExerciseFeed exerciseType="standingextension" />} />
            <Route path="flexiononawall_feed" element={<ExerciseFeed exerciseType="flexiononawall" />} />
            <Route path="quadrupedrockback_feed" element={<ExerciseFeed exerciseType="quadrupedrockback" />} />
            <Route path="squat_feed" element={<ExerciseFeed exerciseType="squat" />} />
            <Route path="standingkneeflexion_feed" element={<ExerciseFeed exerciseType="standingkneeflexion" />} />

          </Routes>
        </div>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default NeoNimble;
