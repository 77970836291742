import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Home } from "../assets/images/Home.svg";
import { ReactComponent as Account } from "../assets/images/Account.svg";
import { ReactComponent as Docs } from "../assets/images/Docs.svg";
import { ReactComponent as Help } from "../assets/images/Help.svg";

const useSidebar = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const savedLanguage = localStorage.getItem('languagePreference') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const sidebarItems = [
    {
      icon: Home,
      text: t('dashboard'),
    },
    // {
    //   icon: Docs,
    //   text: t('progressreport'),
    // },
    {
      icon: Account,
      text: t('account'),
    },
  ];

  let [selectedIndex, setSelectedIndex] = useState(0);
  let [isHover, setisHover] = useState(false);

  let data = { isHover, sidebarItems, selectedIndex };

  const handleClick = (index) => {
    setSelectedIndex(index);
  };

  const handleMouseOver = () => {
    setisHover(true);
  };

  const handleMouseOut = () => {
    setisHover(false);
  };

  let fns = { handleMouseOver, handleMouseOut, handleClick };
  return { data, fns };
};

export default useSidebar;
