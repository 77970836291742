import "./intro.css";
import IntroImg from "../../img/intro-image.png";
import IntroImgMobile from "../../img/intro-img-mobile.png";

const Intro = () => {
  return (
    <section id="intro">
      <div className="i">
        <div className="i-left">
          <div className="i-left-wrapper">
            <div className="i-intro">
              AI-powered revolution in Physical Therapy and Rehab: Personalized
              home exercises with real-time feedback
            </div>

            <div className="image-container">
              <img src={IntroImgMobile} alt="" className="mobile-img" />
            </div>

            <div className="i-desc">
              <p>B2B tech platform for physiotherapists & corporates</p>
              <p className="i-desc-p">Customized home exercises</p>
              <p className="i-desc-p">
                Real-time monitoring & precision guidance
              </p>
              <p className="i-desc-p">Improved adherence & faster recovery</p>
            </div>
          </div>
        </div>
        <div className="i-right">
          <img src={IntroImg} alt="" className="desktop-img" />
          {/* <img src={IntroImgMobile} alt="" className="mobile-img" /> */}
        </div>
      </div>
    </section>
  );
};

export default Intro;
