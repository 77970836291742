import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import "./docFeedbackBar.css";


function docFeedbackBar() {
    // const { t, i18n } = useTranslation();

    // useEffect(() => {
    //     const savedLanguage = localStorage.getItem('languagePreference') || 'en';
    //     i18n.changeLanguage(savedLanguage);
    // }, [i18n]);

    return (
        <div className="docFeedbackBar">
            Welcome back to neonimble.ai, Dr. KP Shivratna
        </div>
    );
}

export default docFeedbackBar;
