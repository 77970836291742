import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NavBar from "../navbar/NavBar";
import FeedbackBar from "../feedbackbar/FeedbackBar";
import Upcoming from "../upcoming/Upcoming";
import WhatsAppChat from "../whatsappchat/WhatsAppChat";

import Standingwallslide from "../assets/images/standingwallslide.png";
import Standingextension from "../assets/images/standingextension.png";
import Flexiononawall from "../assets/images/flexiononawall.png";
import QuadrupedRockBack from "../assets/images/quadrupedrockback.png";
import Squats from "../assets/images/squats.png";
import StandingKneeFlexion from "../assets/images/standingkneeflexion.png";

import EventCard from "../eventcard/EventCard";

import "./Dashboard.css";

function Dashboard() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('languagePreference') || 'en';
    i18n.changeLanguage(savedLanguage);

    const username = localStorage.getItem('username');
    if (username) {
      sendUsernameToBackend(username);
    }
  }, [i18n]);

  const sendUsernameToBackend = async (username) => {
    const url = 'https://neonimble.ai/username_receive';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });
      const responseData = await response.json();
      console.log('Response from backend:', responseData);
    } catch (error) {
      console.error('Error sending username to backend:', error);
    }
  };

  return (
    <div className="TypeQuestSidebarOpen">
      <div className="Page">
        <div className="Content">
          <div className="Sec1">
            <NavBar />
            <div className="HeroContent"></div>
            <FeedbackBar className="FeedbackBar1" />
          </div>

          <div className="Sec3">
            <div className="UpcomingEvents">{t('todaysgoal')}</div>
            <div className="Items">
              <EventCard
                itemName={t('standingwallslide')}
                itemDetails={`12 ${t('reps')}`}
                videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                navigationPath="standingwallslide"
                imageUrl={Standingwallslide}
              />
              <EventCard
                itemName={t('standingextension')}
                itemDetails={`10 ${t('reps')}`}
                videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                navigationPath="standingextension"
                imageUrl={Standingextension}
              />
              <EventCard
                itemName={t('flexiononawall')}
                itemDetails={`8 ${t('reps')}`}
                videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                navigationPath="flexiononawall"
                imageUrl={Flexiononawall}
              />
              <EventCard
                itemName={t('quadrupedrockback')}
                itemDetails={`10 ${t('reps')}`}
                videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                navigationPath="quadrupedrockback"
                imageUrl={QuadrupedRockBack}
              />
              <EventCard
                itemName={t('squats')}
                itemDetails={`8 ${t('reps')}`}
                videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                navigationPath="squat"
                imageUrl={Squats}
              />
              <EventCard
                itemName={t('standingkneeflexion')}
                itemDetails={`10 ${t('reps')}`}
                videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                navigationPath="standingkneeflexion"
                imageUrl={StandingKneeFlexion}
              />
            </div>
          </div>

          <div className="Sec2">
            {/* <Upcoming />
            <WhatsAppChat /> */}
          </div>
          <div className="Sec4"></div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
