import React, { useEffect } from "react";
import { Avatar } from "@mui/material";
import Avatar1Image from "../assets/images/no-profile-picture-icon.svg";
import { styled } from "@mui/material/styles";
import SidebarRow from "./sidebarRow/SideBarRow";
import useSidebar from "./useSidebar";
import { useTranslation } from "react-i18next";
import "./Sidebar.css";

import { Link } from "react-router-dom";

const CollapsableOpen = styled("div", {
  shouldForwardProp: (prop) => !["data"].includes(prop),
})();

const Logo = styled("div", {
  shouldForwardProp: (prop) => !["data"].includes(prop),
})();

function Sidebar() {
  const { data, fns } = useSidebar();
  const sidebarRoutes = ["/Dashboard", "/Account", "/Help"];
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('languagePreference') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  return (
    <CollapsableOpen
      className="collapsable-open"
      onMouseEnter={fns.handleMouseOver}
      onMouseLeave={fns.handleMouseOut}
      style={{ width: data.isHover ? "290px" : "90px" }}
    >
      <div className="top">

        {/* <Logo className="logo" data={data}>
          {data.isHover ? (
            <div className="moonbase-logo">neonimble.ai</div>
          ) : (
            <div className="moonbase-logo-m">n</div>
            // <img className="image-logo" src={NeoNimbleLogoWhite} />
          )}
        </Logo> */}

        <div className="list">
          {data.sidebarItems &&
            data.sidebarItems.map((sidebarItem, index) => (
              <Link
                to={sidebarRoutes[index]}
                key={index}
                className="sidebar-link"
              >
                <SidebarRow
                  className="sidebar-row-1"
                  sidebarItem={sidebarItem}
                  isSelected={index === data.selectedIndex}
                  handleClick={() => fns.handleClick(index)}
                  sidebarHover={data.isHover}
                />
              </Link>
            ))}
        </div>
      </div>
      <div className="account-1">
        <Link to="/Account" className="avatar-row">
          <Avatar
            className="avatar-1"
            variant="circular"
            src={Avatar1Image}
            alt={"Avatar"}
          />
          {!!data.isHover ? (
            <div className="full-name">{t('name')}</div>
          ) : null}
        </Link>
      </div>
    </CollapsableOpen>
  );
}

export default Sidebar;
