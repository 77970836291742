import React, { useState, useRef, useEffect } from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
import "./docEventCard.css";
import { useNavigate } from "react-router-dom";

const DocEventCard = ({
    itemName,
    itemDetails,
    videoId,
    navigationPath,
    imageUrl,
}) => {
    const navigate = useNavigate();
    const [showVideo, setShowVideo] = useState(false);
    const videoPlayer = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('languagePreference') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleOpenVideo = () => {
        setShowVideo(true);
    };

    const handleCloseVideo = () => {
        setShowVideo(false);
    };

    const handleStartClick = () => {
        navigate(`/${navigationPath}`, { replace: true });
    };

    const onDialogEnter = () => {
        const { current: player } = videoPlayer;
        if (player) {
            const rect = player.wrapper.getBoundingClientRect();
            const width = window.innerWidth;
            const height = window.innerHeight;
            const scaleFactor = Math.min(width / rect.width, height / rect.height);
            player.internalPlayer.setSize(
                rect.width * scaleFactor,
                rect.height * scaleFactor
            );
        }
    };

    const desktopPlayerOpts = {
        height: "480",
        width: "720",
    };

    const mobilePlayerOpts = {
        height: "200",
        width: "300",
    };

    const playerOpts = isMobile ? mobilePlayerOpts : desktopPlayerOpts;

    return (
        <div className="EventCard">
            <div className="Image">
                <img src={imageUrl} alt="Description of the image" />
            </div>

            <div className="TextButton">
                <div className="Details">
                    <div className="ItemName">{itemName}</div>
                    <div className="ItemPrice">{itemDetails}</div>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                    <Button
                        variant="contained"
                        size="large"
                        style={{
                            marginRight: "10px", fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 550,
                            backgroundColor: '#68CD8F'
                        }}
                        onClick={handleOpenVideo}
                    >
                        {t('demo')}
                    </Button>
                </div>
                <Dialog
                    open={showVideo}
                    onClose={handleCloseVideo}
                    maxWidth={false}
                    onEnter={onDialogEnter}
                    PaperProps={{
                        style: {
                            backgroundColor: "transparent",
                            boxShadow: "none",
                        },
                    }}
                >
                    <DialogContent style={{ padding: 0 }}>
                        <div className="youtubePlayerWrapper">
                            <YouTube videoId={videoId} opts={playerOpts} ref={videoPlayer} />
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
};

export default DocEventCard;
