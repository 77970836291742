import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./ExercisePage.css";


import standingwallslideExerciseVideo from "../assets/images/StandingWallSlidesVideo.mp4";
import standingextensionExerciseVideo from "../assets/images/StandingExtensionVideo.mp4";
import flexiononawallExerciseVideo from "../assets/images/FlexionOnAWallVideo.mp4";
import quadrupedrockbackExerciseVideo from "../assets/images/QuadrupedRockBack.mp4";
import squatExerciseVideo from "../assets/images/Squats.mp4";
import standingkneeflexionExerciseVideo from "../assets/images/StandingKneeFlexion.mp4"

const ExercisePage = ({ exerciseType }) => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('languagePreference') || 'en';
    i18n.changeLanguage(savedLanguage);

    const username = localStorage.getItem('username');
    if (username) {
      sendUsernameToBackend(username);
    }
  }, [i18n]);

  const sendUsernameToBackend = async (username) => {
    const url = 'https://neonimble.ai/username_receive_midpage';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });
      const responseData = await response.json();
      console.log('Response from backend:', responseData);
    } catch (error) {
      console.error('Error sending username to backend:', error);
    }
  };

  const handleStartExercise = () => {
    fetch("/./", {
      method: "POST",
    })
      .then((response) => {
        console.log(response);
        navigate(`/${exerciseType}_feed`);
        window.location.reload(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleBack = () => {
    fetch("/./", {
      method: "POST",
    })
      .then((response) => {
        console.log(response);
        navigate("/Dashboard");
        window.location.reload(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  let exerciseVideo;
  let exerciseName;

  switch (exerciseType) {
    case "standingwallslide":
      exerciseVideo = standingwallslideExerciseVideo;
      exerciseName = t("standingwallslide");
      break;
    case "standingextension":
      exerciseVideo = standingextensionExerciseVideo;
      exerciseName = t("standingextension");
      break;
    case "flexiononawall":
      exerciseVideo = flexiononawallExerciseVideo;
      exerciseName = t("flexiononawall");
      break;
    case "quadrupedrockback":
      exerciseVideo = quadrupedrockbackExerciseVideo;
      exerciseName = t("quadrupedrockback");
      break;
    case "squat":
      exerciseVideo = squatExerciseVideo;
      exerciseName = t("squats");
      break;
    case "standingkneeflexion":
      exerciseVideo = standingkneeflexionExerciseVideo;
      exerciseName = t("standingkneeflexion");
      break;
    default:
      break;
  }

  return (
    <div className="ExercisePage">
      <div className="TopSection">
        <h1>{exerciseName}</h1>
        <video
          ref={videoRef}
          width="100%"
          height="350"
          controls
          className="ExerciseVideo"
        >
          <source src={exerciseVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="BottomSection">
        <div className="ButtonsContainer">
          <Button
            variant="contained"
            size="large"
            color="info"
            className="Button"
            onClick={handleBack}
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 550,
            }}
          >
            {t("goback")}
          </Button>

          <Button
            variant="contained"
            size="large"
            color="success"
            className="Button"
            onClick={handleStartExercise}
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 550,
            }}
          >
            {t("startexercise")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExercisePage;
