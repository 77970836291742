import React, { useState, useRef, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./patientCard.css";
import { useTranslation } from "react-i18next";

const PatientCard = ({
    itemName,
    itemDetails,
    navigationPath,
    imageUrl,
}) => {
    const navigate = useNavigate();
    const [showVideo, setShowVideo] = useState(false);
    const videoPlayer = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handleOpenVideo = () => {
        setShowVideo(true);
    };

    const handleCloseVideo = () => {
        setShowVideo(false);
    };

    const handleStartClick = () => {
        navigate(`/${navigationPath}`, { replace: true });
    };

    const onDialogEnter = () => {
        const { current: player } = videoPlayer;
        if (player) {
            const rect = player.wrapper.getBoundingClientRect();
            const width = window.innerWidth;
            const height = window.innerHeight;
            const scaleFactor = Math.min(width / rect.width, height / rect.height);
            player.internalPlayer.setSize(
                rect.width * scaleFactor,
                rect.height * scaleFactor
            );
        }
    };

    const desktopPlayerOpts = {
        height: "480",
        width: "720",
    };

    const mobilePlayerOpts = {
        height: "200",
        width: "300",
    };

    const playerOpts = isMobile ? mobilePlayerOpts : desktopPlayerOpts;

    return (
        <div className="EventCard">
            <div className="Image">
                <img src={imageUrl} alt="Description of the image" />
            </div>

            <div className="TextButton">
                <div className="Details">
                    <div className="ItemName">{itemName}</div>
                    <div className="ItemPrice">{itemDetails}</div>
                </div>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                    <Button
                        variant="contained"
                        size="small"
                        color="info"
                        style={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 550,
                        }}
                        onClick={handleStartClick}
                    >
                        {'More Details'}
                    </Button>
                </div>

            </div>
        </div>
    );
};

export default PatientCard;
