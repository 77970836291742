import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import './ProgressReport.css';
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const ProgressReport = () => {
  const [jsonData, setJsonData] = useState(null);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    fetch("/./", {
      method: "POST",
    })
      .then((response) => {
        console.log(response);
        navigate("/patient");
        window.location.reload(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('languagePreference') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  useEffect(() => {
    const username = localStorage.getItem('username');

    fetch('/combined_frames_data')
      .then(response => response.json())
      .then(data => {
        if (data[username]) {
          setJsonData(data[username]);
        } else {
          console.warn(`No data found for username: ${username}`);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    const startAnimationTimeout = setTimeout(() => {
      setAnimationStarted(true);
    }, 500);

    return () => clearTimeout(startAnimationTimeout);
  }, []);

  const [animationStarted, setAnimationStarted] = useState(false);
  const animationDuration = 10000;

  if (!jsonData) {
    // Return a loading state or spinner while data is being fetched
    return <div>Loading...</div>;
  }

  return (
    <div className='MainDiv'>
      <div className='ProgressBarDiv'>
        {Object.keys(jsonData).map((exerciseName, index) => {
          const { totalaccuracy, repaccuracy } = jsonData[exerciseName];
          const roundedAccuracy = Math.round(totalaccuracy);
          const textSize = 50;

          return (
            <div key={index} className="progress-bar-container">
              <div className="progress-bar-container-two">
                <h1>
                  {exerciseName === 'standingwallslide' ? t('standingwallslide') :
                    exerciseName === 'standingextension' ? t('standingextension') :
                      exerciseName === 'quadrupedrockback' ? t('quadrupedrockback') :
                        exerciseName === 'flexiononawall' ? t('flexiononawall') :
                          exerciseName === 'squat' ? t('squat') :
                            exerciseName}
                </h1>
              </div>

              <div className="progress-bar-container-one">
                <div className="line-chart-container">
                  <ResponsiveContainer height={300}>
                    <BarChart data={repaccuracy} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <XAxis dataKey="reps" label={{ value: `${t("repsgraph")} `, position: 'insideBottom', dy: 7 }} />
                      <YAxis label={{ value: `${t("accuracy")} (%)`, angle: -90, position: 'insideLeft' }} />
                      <Tooltip />

                      <Bar dataKey="accuracy" name="Accuracy (%)"  >
                        {
                          repaccuracy.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.accuracy > 50 ? '#3BB371' : '#E06666'} />
                          ))
                        }
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                <div className='progressDiv'>
                  <CircularProgressbar
                    value={animationStarted ? roundedAccuracy : 0}
                    text={`${t("accuracy")} ${roundedAccuracy}%`}
                    styles={buildStyles({
                      textColor: '#1a1a1a',
                      pathColor: '#1384D6',
                      trailColor: '#d6d6d6',
                      textSize: `${textSize}%`,
                    })}
                    className="CircularProgressbar"
                    initialAnimation={false}
                    animate={animationStarted}
                    duration={animationDuration}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {location.pathname === '/patientProgress' && (
        <div className='botton-back-btn'>
          <Button
            variant="contained"
            size="small"
            color="info"
            style={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 550,
              textTransform: 'none'
            }}
            onClick={handleBack}
          >
            {'Go Back'}
          </Button>
        </div>
      )}
    </div >
  );
};

export default ProgressReport;
