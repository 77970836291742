import React, { useState, useEffect } from "react";
import "./Account.css";
import Avatar1Image from "../assets/images/no-profile-picture-icon.svg";
import { useTranslation } from 'react-i18next';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "Arsh Guleria",
    enrollmentDate: "17, 2023",
    doctor: "Dr. KP Shivratna",
    language: localStorage.getItem('languagePreference') || 'en',
    languages: [
      { code: 'en', name: 'English' },
      { code: 'hi', name: 'हिंदी' },
      { code: 'gu', name: 'ગુજરાતી' },
      { code: 'ma', name: 'मराठी' },
    ],
  });

  const handleLogout = () => {
    fetch("/./", {
      method: "POST",
    })
      .then((response) => {
        console.log(response);
        navigate("/Login");
        window.location.reload(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguageCode = localStorage.getItem('languagePreference') || 'en';
    i18n.changeLanguage(savedLanguageCode);
  }, [i18n]);

  const handleLanguageChange = (e) => {
    const selectedLanguageCode = e.target.value;
    localStorage.setItem('languagePreference', selectedLanguageCode);
    i18n.changeLanguage(selectedLanguageCode);
    setUserData({
      ...userData,
      language: selectedLanguageCode,
    });
  };

  return (
    <div className="account-container">
      <div className="user-info">
        <div className="user-image">
          <img src={Avatar1Image} alt="User" />
        </div>
        <div className="user-details">
          {/* <h2>{userData.name}</h2> */}
          <h2>{t('name')}</h2>
          <p className="pp">{t('enrollementdate')}: {t('month')} {userData.enrollmentDate}</p>
          <p className="pp">{t('doctorallocated')}: {t('doctorname')}</p>
          {/* <div className="language-preference">
            <label className="pp" htmlFor="languageSelect">{t('languagepreference')}: </label>
            <select
              id="languageSelect"
              value={userData.language}
              onChange={handleLanguageChange}
            >
              {userData.languages.map((lang, index) => (
                <option key={index} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </select>
          </div> */}
        </div>
      </div>
      {/* <div className="language-preference">
        <label className="pp" htmlFor="languageSelect">{t('languagepreference')}: </label>
        <select
          id="languageSelect"
          value={userData.language}
          onChange={handleLanguageChange}
        >
          {userData.languages.map((lang, index) => (
            <option key={index} value={lang.code}>
              {lang.name}
            </option>
          ))}
        </select>
      </div> */}
      <div className="logout-btn">
        <Button style={{
          color: '#fffff',
          fontFamily: "'Montserrat', sans-serif",
          backgroundColor: "#7E7E7E",
        }}
          type="submit" variant="contained" size="small" onClick={handleLogout}>
          {t("logout")}
        </Button>
      </div>
    </div >
  );
};

export default Account;
