import { Link } from "react-router-dom";
import "./Docnavbar.css";
import NeoNimbleLogoWhite from "../../assets/images/NeoNimbleLogoWhite.jpeg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


function DocNavBar() {
    const navigate = useNavigate();
    const handleLogout = () => {
        fetch("/./", {
            method: "POST",
        })
            .then((response) => {
                console.log(response);
                navigate("/Login");
                window.location.reload(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    return (
        <div className="NavBar1-doc-main">
            <div className="NavBar1-doc">
                <Link to="https://www.neonimble.ai" target="_blank" className="Account">
                    <img className="image-logo" src={NeoNimbleLogoWhite} alt="NeoNimble Logo" />
                </Link>
                {'neonimble.ai'}
            </div>
            <div className="doc-log-out">
                <Button style={{
                    color: '#fffff',
                    fontFamily: "'Montserrat', sans-serif",
                    backgroundColor: "#7E7E7E",
                    textTransform: 'none'
                }}
                    type="submit" variant="contained" size="small" onClick={handleLogout}>
                    LogOut
                </Button></div>
        </div>

    );
}

export default DocNavBar;
