import { useState } from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";
import NeoNimbleLogo from "../assets/images/neonimblelogo.png"

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const menuItems = [
    { title: "Dashboard", path: "/Dashboard" },
    { title: "Progress Report", path: "/ProgressReport" },
    { title: "Account", path: "/Account" },
    { title: "Help", path: "/Help" },
  ];

  return (
    <div className="header">
      <img
        className="moonbase-logo"
        src={NeoNimbleLogo}
        alt={"NeoNimbleLogo"}
      />
      <div className="header-right">
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          &#9776;
        </div>
        <div
          className={`header-menu ${isMobileMenuOpen ? "mobile-menu-open" : ""
            }`}
        >
          {menuItems.map((item, index) => (
            <NavLink
              key={index}
              className="header-menu-item"
              activeClassName="active"
              to={item.path}
              onClick={closeMobileMenu}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
