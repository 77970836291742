import React, { useState } from "react";
import "./login.css";
import { Button } from "@mui/material";
import NeoNimbleLogoWhite from "../assets/images/NeoNimbleLogoWhite.jpeg";
import introImg from '../assets/images/intro-img-mobile.png'
import { ReactComponent as HelpIcon } from "../assets/images/customer.svg";



const Login = () => {
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const database = [
        {
            username: "user1",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "user2",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "user3",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "user4",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "user5",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "user6",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "user7",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "user8",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "user9",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "user10",
            password: "password",
            redirect: "/Dashboard"
        },
        {
            username: "doctor",
            password: "password",
            redirect: "/DoctorsDashboard"
        }
    ];

    const errors = {
        uname: "Invalid username",
        pass: "Invalid password"
    };

    const handleSubmit = (event) => {
        // Prevent page reload
        event.preventDefault();

        var { uname, pass } = event.target;

        // Find user login info
        const userData = database.find((user) => user.username === uname.value);

        // Compare user info
        if (userData) {
            if (userData.password !== pass.value) {
                // Invalid password
                setErrorMessages({ name: "pass", message: errors.pass });
            } else {
                // Set username in localStorage
                localStorage.setItem("username", uname.value);
                setIsSubmitted(true);
                window.location.href = userData.redirect;
            }
        } else {
            // Username not found
            setErrorMessages({ name: "uname", message: errors.uname });
        }
    };

    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    const renderForm = (
        <div className="form">
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Username </label>
                    <input type="text" name="uname" required />
                    {renderErrorMessage("uname")}
                </div>
                <div className="input-container">
                    <label>Password </label>
                    <input type="password" name="pass" required />
                    {renderErrorMessage("pass")}
                </div>
                <div className="button-container">
                    <Button style={{
                        color: '#fffff',
                        fontFamily: "'Montserrat', sans-serif",
                    }}
                        type="submit" variant="contained" color="info" size="medium" >
                        Login
                    </Button>
                </div>


            </form>
        </div >
    );

    return (
        <div className="main">
            <div className="header-login">
                <div className="left-side">
                    <img className="image-logo" src={NeoNimbleLogoWhite} alt="NeoNimble Logo" />
                    neonimble.ai
                </div>

                <div className="header-btn">
                    <Button style={{
                        color: '#ffffff',
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: "600",
                        backgroundColor: "#2D2D2D",
                        textTransform: 'none'
                    }}
                        type="submit" variant="contained" size="small" endIcon={<HelpIcon />}  >
                        Help
                    </Button>
                </div>
            </div>
            <div className="app">
                <div className="left-container">

                    <div className="taglines">
                        <div className="tagline-3">
                            <div className="heading">neonimble.ai</div>
                        </div>
                        <div class="line"></div>
                        <div className="tagline">Making your every move right!!</div>
                    </div>
                    <div className="image-sub">
                        <img className="intro-img" src={introImg} />
                    </div>

                </div>
                <div className="right-container">
                    <div className="login-form">
                        <div className="title">Welcome back to neonimble.ai</div>
                        {isSubmitted ? (
                            <div>User is successfully logged in</div>
                        ) : (
                            renderForm
                        )}
                    </div>
                </div>

            </div>
        </div>

    );
}

export default Login;


