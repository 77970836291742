import React, { useState } from 'react';
import './wired.css';
import FrontView from '../../assets/images/frontalView.png';
import BackView from '../../assets/images/backView.png';
import Switch from '@mui/material/Switch';
import JointModal from '../jointmodal/JointModal';

const Test = () => {
    const [showFrontView, setShowFrontView] = useState(true);
    const [selectedJoint, setSelectedJoint] = useState(null);
    const [selectedJointSide, setSelectedJointSide] = useState(null);

    const handleToggle = () => {
        setShowFrontView(prevState => !prevState);
    };

    const handleMouseOver = (event) => {
        const target = event.target;
        const className = target.className;
        if (className.includes('points')) {
            const jointName = className.split(' ')[1];
            const formattedJointName = jointName.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
            target.setAttribute('data-tooltip', formattedJointName);
        }
    };

    const handleMouseOut = (event) => {
        const target = event.target;
        target.removeAttribute('data-tooltip');
    };

    const handlePointClick = (event) => {
        const target = event.target;
        const jointName = target.className.split(' ')[1];
        const side = jointName.includes('left') ? 'left' : 'right';
        setSelectedJoint(jointName);
        setSelectedJointSide(side);
    };

    const handleCloseModal = () => {
        setSelectedJoint(null);
        setSelectedJointSide(null);
    };

    return (
        <div className='big-div'>
            <div className="container-img" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <img src={showFrontView ? FrontView : BackView} alt="Displayed Image" className={`centered-image ${showFrontView ? '' : 'back-view'}`} />
                <div className='toggle-switch'>
                    <span>Back View</span>
                    <Switch
                        checked={showFrontView}
                        onChange={handleToggle}
                        color="primary"
                        inputProps={{ 'aria-label': 'toggle between front and back view' }}
                    />
                    <span>Front View</span>
                </div>
                {showFrontView ? (
                    <>
                        <div className='points left-elbow' onClick={handlePointClick} />
                        <div className='points left-shoulder' onClick={handlePointClick} />
                        <div className='points left-knee' onClick={handlePointClick} />
                        <div className='points left-ankle' onClick={handlePointClick} />
                        <div className='points right-elbow' onClick={handlePointClick} />
                        <div className='points right-shoulder' onClick={handlePointClick} />
                        <div className='points right-knee' onClick={handlePointClick} />
                        <div className='points right-ankle' onClick={handlePointClick} />
                    </>
                ) : (
                    <>
                        <div className='points neck' onClick={handlePointClick} />
                        <div className='points lower-back' onClick={handlePointClick} />
                        <div className='points left-hip' onClick={handlePointClick} />
                        <div className='points right-hip' onClick={handlePointClick} />
                    </>
                )}
            </div>
            {selectedJoint && <JointModal jointName={selectedJoint} side={selectedJointSide} onClose={handleCloseModal} />}
        </div>
    );
};

export default Test;








