import * as pose from '@mediapipe/pose'
import smoothLandmarks from 'mediapipe-pose-smooth';
import * as cam from "@mediapipe/camera_utils"
import * as drawingUtils from "@mediapipe/drawing_utils"
import { useRef, useEffect, useState } from "react"
import "./FrontMediapipe.css";
import silhoutte from "../../assets/images/full_body_silhouette.png"

import standingWallSlideSilhouette from "../../assets/images/standingwallslide_silhouette.png";
import standingExtensionSilhouette from "../../assets/images/standingextension_silhouette.png";
import flexionOnAWallSilhouette from "../../assets/images/flexiononawall_silhouette.png";
import quadrupedRockBackSilhouette from "../../assets/images/quadrupedrockback_silhouette.png";
import squatSilhouette from "../../assets/images/squat_silhouette.png";
import standingKneeFlexionSilhouette from "../../assets/images/standingkneeflexion_silhouette.png";



const FrontMediapipe = ({ exerciseType, status }) => {
    const webcamRef = useRef(null)
    const canvasRef = useRef(null)
    var camera = null
    const [didLoad, setdidLoad] = useState(false)

    const silhouetteMapping = {
        standingwallslide: standingWallSlideSilhouette,
        standingextension: standingExtensionSilhouette,
        flexiononawall: flexionOnAWallSilhouette,
        quadrupedrockback: quadrupedRockBackSilhouette,
        squat: squatSilhouette,
        standingkneeflexion: standingKneeFlexionSilhouette
    };


    function extractLandmarksAndVisibility(results) {
        const landmarks = [];
        const visibilityList = [];
        Object.values(results.poseLandmarks).forEach(landmark => {
            landmarks.push([landmark.x, landmark.y, landmark.z]);
            visibilityList.push(landmark.visibility);
        });
        return { landmarks, visibilityList };
    }

    function extractLandmarksAndVisibility(results) {
        const landmarks = [];
        const visibilityList = [];
        Object.values(results.poseLandmarks).forEach(landmark => {
            landmarks.push([landmark.x, landmark.y, landmark.z]);
            visibilityList.push(landmark.visibility);
        });
        return { landmarks, visibilityList };
    }

    function onResults(results) {
        const canvasElement = canvasRef.current;
        const canvasCtx = canvasElement.getContext("2d");

        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

        if (results.poseLandmarks) {
            console.log(pose.POSE_CONNECTIONS)
            drawingUtils.drawConnectors(canvasCtx, results.poseLandmarks, pose.POSE_CONNECTIONS, { visibilityMin: 0.65, color: 'white' });
            drawingUtils.drawLandmarks(canvasCtx, Object.values(pose.POSE_LANDMARKS_LEFT)
                .map(index => results.poseLandmarks[index]), { visibilityMin: 0.65, color: 'white', fillColor: 'white' });
            drawingUtils.drawLandmarks(canvasCtx, Object.values(pose.POSE_LANDMARKS_RIGHT)
                .map(index => results.poseLandmarks[index]), { visibilityMin: 0.65, color: 'white', fillColor: 'white' });
            drawingUtils.drawLandmarks(canvasCtx, Object.values(pose.POSE_LANDMARKS_NEUTRAL)
                .map(index => results.poseLandmarks[index]), { visibilityMin: 0.65, color: 'white', fillColor: 'white' });

            const { landmarks, visibilityList } = extractLandmarksAndVisibility(results);

            const username = localStorage.getItem("username");
            sendToBackend(landmarks, visibilityList, username);

        }
        canvasCtx.restore();
    }

    async function sendToBackend(landmarks, visibilityList, username) {
        const url = `https://neonimble.ai/${exerciseType}_receive_data`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ lmlist: landmarks, visibilityList, username }),
            });
            const responseData = await response.json();
            console.log('Response from backend:', responseData);
        } catch (error) {
            console.error('Error sending data to backend:', error);
        }
    }

    useEffect(() => {
        if (!didLoad) {
            const mpPose = new pose.Pose({
                locateFile: (file) => {
                    return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
                },
            });
            mpPose.setOptions({
                selfieMode: true,
                modelComplexity: 1,
                smoothLandmarks: true,
                enableSegmentation: false,
                smoothSegmentation: true,
                minDetectionConfidence: 0.5,
                minTrackingConfidence: 0.5,
            });

            camera = new cam.Camera(webcamRef.current, {
                onFrame: async () => {
                    const canvasElement = canvasRef.current
                    const aspect = window.innerHeight / window.innerWidth;
                    let width, height;
                    if (window.innerWidth > window.innerHeight) {
                        height = window.innerHeight;
                        width = height / aspect;
                    }
                    else {
                        width = window.innerWidth;
                        height = width * aspect;
                    }
                    canvasElement.width = width;
                    canvasElement.height = height;
                    await mpPose.send({ image: webcamRef.current });
                }
            })
            camera.start();

            mpPose.onResults((results) => smoothLandmarks(results, onResults));
            setdidLoad(true)
        }
    }, [didLoad])

    const silhouetteImage = silhouetteMapping[exerciseType] || silhoutte;

    return (
        <div>
            <div style={{ position: 'relative' }}>
                <video className="input_video" ref={webcamRef} />
                <canvas ref={canvasRef} className='output_canvas'></canvas>
                {status === 'outofframe' && (
                    <img
                        src={silhouetteImage}
                        alt="Full body silhouette"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: '38%',
                            width: '120%',
                            height: '100%',
                            opacity: 0.6
                        }}
                    />
                )}
            </div>
        </div>
    );
}

export default FrontMediapipe;
