import "./vision.css";
import Visionimg from "../../img/vision.png";

const Vision = () => {
  return (
    <section id="vision">
      <div className="a">
        <div className="a-left">
          <div className="a-card">
            <img src={Visionimg} alt="vision_img" className="a-img" />
          </div>
        </div>
        <div className="a-right">
          <div className="a-title">
            Home Exercises:
            <br />
            Personalized & Simplified
          </div>
          <p className="a-desc">
            Ease & Simplicity: Without any sensors or special wearables
          </p>

          <p className="a-desc">
            Freedom & Convenience: Use any device anywhere on demand
          </p>

          <p className="a-desc">
            Confidence & Adherence: Progress tracking & instant feedback
          </p>
        </div>
      </div>
    </section>
  );
};

export default Vision;
