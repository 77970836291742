import React from "react";
import "./articles.css";

const NewsCard = ({ title, description, image, link }) => {
  const handleClick = () => {
    window.open(link, "_blank");
  };

  return (
    <div className="news-card" onClick={handleClick}>
      <img src={image} alt={title} className="news-image" />
      <div className="news-content">
        <h3 className="news-title">{title}</h3>
        <p className="news-description">{description}</p>
        <a
          href={link}
          className="news-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read More
        </a>
      </div>
    </div>
  );
};

const Articles = () => {
  return (
    <section id="articles">
      <div className="n">
        <div className="n-heading">
          Musculoskeletal Disorders (MSD):
          <br /> Leading cause of disability worldwide{" "}
        </div>

        <div className="news">
          <NewsCard
            title="1.71 billion people have musculoskeletal conditions worldwide"
            description="Musculoskeletal (MSK) conditions affect many people and can affect your joints, bones and muscles and sometimes associated..."
            image="https://cloudfront-us-east-2.images.arcpublishing.com/reuters/NZLNMIXKZRNFZGQCQCPEJAXQNE.jpg"
            link="https://www.who.int/news-room/fact-sheets/detail/musculoskeletal-conditions"
          />
          <NewsCard
            title="30 million working days lost every year due to MSD in the UK"
            description="Musculoskeletal health refers to the performance of the locomotor system, comprising intact muscles, bones, joints and adjacent connective tissues. Musculoskeletal impairments ..."
            image="https://www.aboutmanchester.co.uk/wp-content/uploads/2018/01/nhs-scheme2-696x391.jpg"
            link="https://www.england.nhs.uk/elective-care-transformation/best-practice-solutions/musculoskeletal/"
          />
          <NewsCard
            title="Majority of workforce has work related MSD issues"
            description="76% of employees report WMSDs while working from the office, and 82% reported concerns about body pain while working from home..."
            image="https://www.biospectrumindia.com/uploads/articles/22-22182.jpeg"
            link="https://www.biospectrumindia.com/news/98/22182/76-workforce-suffers-from-work-related-musculoskeletal-disorders-godrej-study.html"
          />
        </div>
      </div>
    </section>
  );
};

export default Articles;
