import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ExerciseProgress from "../exercisepage/exerciseProgressReport/ExerciseProgress";

import clickSound from '../assets/images/click.wav';
import Confetti from 'react-confetti';

import standingwallslideExerciseVideo from "../assets/images/StandingWallSlidesVideo.mp4";
import standingextensionExerciseVideo from "../assets/images/StandingExtensionVideo.mp4";
import flexiononawallExerciseVideo from "../assets/images/FlexionOnAWallVideo.mp4";
import quadrupedrockbackExerciseVideo from "../assets/images/QuadrupedRockBack.mp4";
import squatExerciseVideo from "../assets/images/Squats.mp4";
import standingkneeflexionExerciseVideo from "../assets/images/StandingKneeFlexion.mp4";

import repCompletedSound from "../assets/images/rep_completed.wav";
import finishedSound from "../assets/images/finished.wav";

import "./ExerciseFeed.css";
import FrontMediapipe from "./frontmediapipe/FrontMediapipe";

const ExerciseFeed = ({ exerciseType }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    const { t, i18n } = useTranslation();
    const username = localStorage.getItem('username');
    const [countdown, setCountdown] = useState(10);

    const videoRef = useRef(null);
    const clickSoundRef = useRef(new Audio(clickSound));

    useEffect(() => {
        const savedLanguage = localStorage.getItem('languagePreference') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    const handleBack = () => {
        fetch("/./", {
            method: "POST",
        })
            .then((response) => {
                console.log(response);
                navigate(`/${exerciseType}`);
                window.location.reload(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const renderProgressModal = () => {
        return (
            <ExerciseProgress
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                exerciseType={exerciseType}
            />
        );
    };

    const handleProgress = () => {
        setIsModalOpen(true);
    };

    let exerciseVideo;
    let exerciseName;

    switch (exerciseType) {
        case "standingwallslide":
            exerciseVideo = standingwallslideExerciseVideo;
            exerciseName = t("standingwallslide");
            break;
        case "standingextension":
            exerciseVideo = standingextensionExerciseVideo;
            exerciseName = t("standingextension");
            break;
        case "flexiononawall":
            exerciseVideo = flexiononawallExerciseVideo;
            exerciseName = t("flexiononawall");
            break;
        case "quadrupedrockback":
            exerciseVideo = quadrupedrockbackExerciseVideo;
            exerciseName = t("quadrupedrockback");
            break;
        case "squat":
            exerciseVideo = squatExerciseVideo;
            exerciseName = t("squats");
            break;
        case "standingkneeflexion":
            exerciseVideo = standingkneeflexionExerciseVideo;
            exerciseName = t("standingkneeflexion");
            break;
        default:
            break;
    }

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [exerciseData, setExerciseData] = useState({
        reps_accuracy: 0,
        reps_completed: 0,
        total_reps: 0,
        message: ''
    });

    const [progress1, setProgress1] = useState(0);
    const [progress2, setProgress2] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowProgress(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/progress_data`);
                const jsonData = await response.json();

                if (jsonData[username]) {
                    const userData = jsonData[username];
                    setExerciseData(userData);
                    setProgress1(userData.reps_accuracy);
                    setProgress2((userData.reps_completed / userData.total_reps) * 100);
                } else {
                    console.warn(`No data found for username: ${username}`);
                }
            } catch (error) {
                console.error('Error fetching progress data:', error);
            }
        };

        fetchData();

        const interval = setInterval(fetchData, 1000);

        return () => clearInterval(interval);
    }, [exerciseType, username]);

    useEffect(() => {
        if (exerciseData.message && exerciseData.message.trim() !== '') {
            const message = new SpeechSynthesisUtterance(exerciseData.message.trim());
            window.speechSynthesis.speak(message);
        }
    }, [exerciseData.message]);

    useEffect(() => {
        let audio;

        if (exerciseData.status === 'completed') {
            audio = new Audio(finishedSound);
            audio.play();
        } else if (exerciseData.reps_completed) {
            audio = new Audio(repCompletedSound);
            audio.play();
        }

        return () => {
            if (audio) {
                audio.pause();
                audio.currentTime = 0;
            }
        };
    }, [exerciseData.reps_completed, exerciseData.total_reps]);

    useEffect(() => {
        let timer;
        if (exerciseData.status === 'countdown' && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 1) {
                        clearInterval(timer);
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(timer);
        };
    }, [exerciseData.status, countdown]);

    useEffect(() => {
        if (exerciseData.status === 'countdown') {
            setCountdown(10);
        }
    }, [exerciseData.status]);

    useEffect(() => {
        if (exerciseData.status === 'countdown' && countdown > 0 && countdown <= 3) {
            clickSoundRef.current.play();
        }
    }, [countdown, exerciseData.status]);

    useEffect(() => {
        if (videoRef.current) {
            if (exerciseData.status === 'alligned') {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [exerciseData.status]);

    return (
        <div>
            <div className="FeedPage">
                <div className="TopSectionFeed" style={{ position: 'relative' }}>
                    {
                        exerciseData.status === 'completed' && (
                            <Confetti
                                width={window.innerWidth}
                                height={window.innerHeight}
                                zIndex={100}
                            />
                        )
                    }
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ flex: 1, position: 'relative', left: '-20%' }}>
                            <FrontMediapipe
                                exerciseType={exerciseType}
                                status={exerciseData.status}
                                style={{ clipPath: 'inset(0 340px 0 0)' }}
                            />
                        </div>

                        <div style={{ flex: 1 }}>
                            <video
                                ref={videoRef}
                                loop
                                className="ExerciseVideo"
                                style={{ position: 'relative', zIndex: 1, objectFit: 'cover', width: '100%', height: '100vh' }}
                            >
                                <source src={exerciseVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>

                    {exerciseData.status === 'alligned' && showProgress && (
                        <div className="progress-container" style={{ position: 'absolute', zIndex: 10, top: '45%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <div style={{ position: "relative" }}>
                                <CircularProgressbar
                                    className='CircularProgressbarTest'
                                    value={progress2}
                                    text={`${exerciseData.reps_completed} / ${exerciseData.total_reps}`}
                                    background
                                    backgroundPadding={6}
                                    styles={buildStyles({
                                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                                        textColor: "#fff",
                                        pathColor: "#fff",
                                        trailColor: "transparent",
                                    })}
                                />
                                <h4 style={{ position: "absolute", top: "60%", left: "50%", transform: "translate(-50%, -50%)", zIndex: "90", color: "white" }}>{t('repsgraph')}</h4>
                            </div>

                            <div className="linear-progress-bar">
                                <div className="linear-progress" style={{
                                    height: `${progress1}%`,
                                    background: `linear-gradient(to bottom,
                                                #0ECD48 0%,    
                                                #E5E10B 40%,   
                                                #D62431 80%)`
                                }}>
                                </div>
                                <div
                                    className="progress-ball"
                                    style={{ position: 'absolute', bottom: `calc(${progress1}% - 15px)` }}
                                >
                                    <span className="reps-accuracy">{exerciseData.reps_accuracy}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {exerciseData.status === 'countdown' ? (
                        <div>
                            <div style={{ position: "absolute", top: "30%", left: "50%", transform: "translate(-50%, -50%)", zIndex: "90", color: "white", fontSize: "24px", backgroundColor: "rgba(0, 0, 0, 0.7)" }}>
                                Exercise starts in
                            </div>
                            <div style={{ position: 'absolute', zIndex: 10, top: '45%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <div className="message-content">
                                    <CircularProgressbar
                                        value={10 - countdown}
                                        maxValue={10}
                                        text={`${countdown}`}
                                        background
                                        backgroundPadding={6}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                                            textColor: "#fff",
                                            pathColor: "#fff",
                                            trailColor: "transparent",
                                            strokeLinecap: "butt"
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        exerciseData.status === 'completed' ? (
                            <div className="message-box" style={{ position: 'absolute', zIndex: 100, top: '80%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <div className="message-content">
                                    Congratulations! You have completed all reps. Please check your progress below.
                                </div>
                            </div>
                        ) : (
                            exerciseData.message && exerciseData.message.trim() !== '' && (
                                <div className={`message-box ${exerciseData.message.trim() === '' ? 'fade-out' : ''}`}>
                                    <div className="message-content">
                                        {exerciseData.message}
                                    </div>
                                </div>
                            )
                        )
                    )}

                </div>

                <div className="BottomSection">
                    <div className="ButtonsContainer">
                        <Button
                            variant="contained"
                            size="large"
                            color="info"
                            className="Button"
                            onClick={handleBack}
                            style={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 700,
                            }}
                        >
                            {t('goback')}
                        </Button>
                        {/* <Button
                            variant="contained"
                            size="large"
                            color="info"
                            className="Button"
                            onClick={handleProgress}
                            style={{
                                fontFamily: "'Montserrat', sans-serif",
                                fontWeight: 700,
                            }}
                        >
                            {t('progress')}
                        </Button> */}
                    </div>
                </div>

                {isModalOpen && renderProgressModal()}
            </div>
        </div>
    );
};

export default ExerciseFeed;
