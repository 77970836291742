// import { useContext } from "react";
import Contact from "./components/contact/Contact";
import Intro from "./components/intro/Intro";
// import Research from "./components/research/Research";
// import Toggle from "./components/toggle/Toggle";
import Solutions from "./components/solutions/Solutions"
import Footer from './components/footer/Footer'
import Header from "./components/header/header";
import Vision from "./components/vision/vision";
import Articles from "./components/articles/Articles";
import ReactGA from 'react-ga4';

function WebPage() {
    const trackingID = 'G-9R2E80TT20';
    ReactGA.initialize(trackingID);
    ReactGA.send({ hitType: "pageview", page: "/" })
    return (
        <div>
            <Header />
            <Intro />
            <Articles />
            <Vision />
            {/* <Research /> */}
            <Solutions />
            <Contact />
            <Footer />
        </div>
    );
};

export default WebPage;
