import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Standingwallslide from "../../assets/images/standingwallslide.png";
import Standingextension from "../../assets/images/standingextension.png";
import Flexiononawall from "../../assets/images/flexiononawall.png";
import { useNavigate } from "react-router-dom";

import { Button, Modal } from "@mui/material";

import DocEventCard from "./docEventCard/docEventCard";
import "./docExerciseAllocation.css";

const DocExerciseAllocation = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);

    const handleBack = () => {
        fetch("/./", {
            method: "POST",
        })
            .then((response) => {
                console.log(response);
                navigate("/patient");
                window.location.reload(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('languagePreference') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    return (
        <div className="DocExerciseAllocation">
            <h1>Allocated Exercise</h1>
            <div className="Items-doc">
                <DocEventCard
                    itemName={t('standingwallslide')}
                    itemDetails={`10 ${t('reps')}`}
                    videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                    navigationPath="standingwallslide"
                    imageUrl={Standingwallslide}
                />
                <DocEventCard
                    itemName={t('standingextension')}
                    itemDetails={`12 ${t('reps')}`}
                    videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                    navigationPath="standingextension"
                    imageUrl={Standingextension}
                />
                <DocEventCard
                    itemName={t('flexiononawall')}
                    itemDetails={`8 ${t('reps')}`}
                    videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                    navigationPath="flexiononawall"
                    imageUrl={Flexiononawall}
                />
            </div>
            <div className="DocExerciseAllocation-btn">
                <Button
                    variant="contained"
                    size="large"
                    color="info"
                    style={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: 550,
                        textTransform: 'none',
                    }}
                    onClick={() => setShowModal(true)}
                >
                    {'Add Exercise'}
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    color="info"
                    style={{
                        fontFamily: "'Montserrat', sans-serif",
                        fontWeight: 550,
                        textTransform: 'none',
                    }}
                    onClick={handleBack}
                >
                    {'Go Back'}
                </Button>
            </div>

            <Modal open={showModal} onClose={() => setShowModal(false)} className="modal-container">
                <div className="modal">
                    <div className="modal-content">
                        <form>
                            <div className="main-input-container">
                                <div className="input-container-add-exe">
                                    <label>Exercise Name </label>
                                    <input type="text-1" name="exerciseName" required />
                                </div>
                                <div className="input-container-add-exe">
                                    <label>Number of reps </label>
                                    <input type="number" name="reps" required />
                                </div>
                            </div>

                            <div className="input-container-add-exe">
                                <label>Video Link </label>
                                <input type="text" name="videoLink" required />
                            </div>

                            <div className="input-container-add-exe">
                                <label>Any specific instructions </label>
                                <textarea name="instructions" rows="2" required />
                            </div>


                            <div className="button-container-add-exe">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="info"
                                    size="medium"
                                    style={{
                                        fontFamily: "'Montserrat', sans-serif",
                                    }}
                                >
                                    Add Exercise
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default DocExerciseAllocation;
