import { Link } from "react-router-dom";
import "./NavBar.css";
import NeoNimbleLogoWhite from "../assets/images/NeoNimbleLogoWhite.jpeg";

function NavBar() {
  return (
    <div className="NavBar1">
      <Link to="https://www.neonimble.ai" target="_blank" className="Account">
        <img className="image-logo" src={NeoNimbleLogoWhite} alt="NeoNimble Logo" />
      </Link>
    </div>
  );
}

export default NavBar;
