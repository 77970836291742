import React from "react";
import "./solutions.css";
import PT from "../../img/physicaltherapy.jpg";
import GF from "../../img/generalfitness.jpg";

const Solutions = () => {
  const boxData = [
    {
      url: PT,
      title: "Physiotherapists Supported with AI Precision",
      desc: [
        "Enabling you to provide personalized care to your patients at home without you physically being available.",
        "Allowing you to build a tailored home exercise routine based on the patient’s conditions with our easy-to-use interface.",
        "Imitating your presence while your patients perform prescribed exercises at home.",
        "Providing real-time corrective guidance to make your patients feel more confident and safe.",
        "Improving patient adherence to home exercises, thus achieving better recovery outcomes.",
        "Monitoring progress and reporting detailed analytics to help you make informed decisions at speed.",
      ],
    },
    {
      url: GF,
      title: "Corporate Wellness Assisted by AI Precision",
      desc: [
        "Majority of our workforce struggle with work-related MSDs (WMSDs), and it is a leading cause of unplanned absenteeism.",
        "neonimble.ai platform enables you to get your team moving beyond pain by taking care of their joints and muscles.",
        "Drawing upon a robust foundation of rigorous research in exercise science and physical fitness, our AI algorithm tailors personalized fitness plans for your employees, aligning with their specific job requirements and fitness levels.",
        "We will provide you with necessary analytics and insights to support you in your employees’ wellness journey.",
      ],
    },
  ];

  return (
    <section id="solutions">
      <div className="s">
        <div className="s-heading">
          Transforming the existing MSD care with neonimble.ai
        </div>
        <div className="s-slides">
          {boxData.map((box, index) => (
            <div key={index} className="s-box">
              <ul className="s-description">
                <div>
                  <div className="s-title">{box.title}</div>
                  {box.desc.map((item, i) => (
                    <li className="s-list" key={i}>
                      {item}
                    </li>
                  ))}
                </div>
                <img src={box.url} alt={box.title} className="s-image" />
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Solutions;
