import React, { useState } from 'react';
import './profilePage.css'; // Import CSS file
import Avatar1Image from "../../assets/images/Avatar_1.png";
import FrozenShoulderImage from "../../assets/images/right-shoulder-illustration.png";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        fetch("/./", {
            method: "POST",
        })
            .then((response) => {
                console.log(response);
                navigate("/DoctorsDashboard");
                window.location.reload(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleProgress = () => {
        fetch("/./", {
            method: "POST",
        })
            .then((response) => {
                console.log(response);
                navigate("/patientProgress");
                window.location.reload(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleExercise = () => {
        fetch("/./", {
            method: "POST",
        })
            .then((response) => {
                console.log(response);
                navigate("/patientExercise");
                window.location.reload(true);
            })
            .catch((error) => {
                console.error(error);
            });
    };


    const [profileData, setProfileData] = useState({
        name: 'Arsh Guleria',
        height: '175 cm',
        weight: '70 kg',
        age: 22,
        bloodType: 'B+',
        address: '123 Main St, Delhi, IN',
        clinicalConditions: ['Frozen Shoulder'],
        shoulderFlexionData: [
            { week: 1, angle: 100 },
            { week: 2, angle: 100 },
            { week: 3, angle: 116 },
            { week: 4, angle: 125 },
            { week: 5, angle: 128 },
            { week: 6, angle: 125 },
            { week: 7, angle: 135 },
            { week: 8, angle: 130 },
        ]

    });

    return (
        <div className="profile-container">
            <h1>User Details</h1>

            <div className='main-profile'>

                <div className='left-side-user'>

                    <div className="user-details">
                        <div className="user-image">
                            <img src={Avatar1Image} alt="User" />
                        </div>
                        <h2>Personal Details</h2>
                        <div className='p-details'>
                            <p>Name: <strong>{profileData.name}</strong></p>
                            <p>Weight: <strong>{profileData.weight}</strong></p>
                            <p>Age: <strong>{profileData.age}</strong></p>
                            <p>Blood Type: <strong>{profileData.bloodType}</strong></p>
                            <p>Address: <strong>{profileData.address}</strong></p>
                            <p>Height: <strong>{profileData.height}</strong></p>
                        </div>

                    </div>
                    <div className="clinical-conditions">
                        <h2>Clinical Conditions</h2>
                        <div className='clinical-conditions-sub'>
                            <img src={FrozenShoulderImage} className='clinical-conditions-img'></img>
                            <ul>
                                {profileData.clinicalConditions.map((condition, index) => (
                                    <li className='clinical-conditions-name' key={index}>{condition}</li>
                                ))}
                            </ul>
                        </div>

                    </div>

                </div>

                <div className='right-side-user'>

                    <div className='user-graph'>
                        <LineChart width={600} height={300} data={profileData.shoulderFlexionData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                            <XAxis dataKey="week" label={{ value: "Week", position: 'insideBottom', dy: 6 }} />
                            <YAxis label={{ value: "Angle", angle: -90, position: 'insideLeft' }} />
                            <CartesianGrid stroke="#f5f5f5" />
                            <Tooltip />
                            <Legend />
                            <Line name='Shoulder Angle Flexion Over Weeks' type="monotone" dataKey="angle" stroke="#ff7300" yAxisId={0} />
                        </LineChart>
                    </div>

                    <div className='user-graph-bottom'>
                        <h2>Exercise Allocated</h2>
                        <div className='clinical-conditions-sub'>
                            <ol>
                                <li>Standing Wall Slide (10 Reps)</li>
                                <li>Standing Extension (12 Reps)</li>
                                <li>Flexion on a wall (8 Reps)</li>
                            </ol>

                        </div>

                    </div>
                </div>

            </div>

            <div className="user-profile-buttons">
                <div>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        style={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 550,
                            textTransform: 'none'
                        }}
                        onClick={handleProgress}
                    >
                        {'Progress Details'}
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        style={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 550,
                            textTransform: 'none'
                        }}
                        onClick={handleExercise}
                    >
                        {'Exercise Details'}
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        size="small"
                        color="info"
                        style={{
                            fontFamily: "'Montserrat', sans-serif",
                            fontWeight: 550,
                            textTransform: 'none'
                        }}
                        onClick={handleBack}
                    >
                        {'Go Back'}
                    </Button>
                </div>
            </div>



        </div >
    );
};

export default ProfilePage;
