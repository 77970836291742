import { SvgIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
import useSidebarRow from "./useSidebarRow";
import "./SideBarRow.css";

const Icon1 = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette["Primary"]["Contrast"],
}));

const Text = styled("div")(({ }) => ({}));

function SideBarRow(props) {
  const { data, fns } = useSidebarRow();
  return (
    <div
      className={`property1-default ${props.sidebarHover ? "sidebar-hover" : ""
        }`}
      onClick={props.handleClick}
      onMouseOver={fns.handleMouseOver}
      onMouseOut={fns.handleMouseOut}
      data-isselected={props.isSelected}
      data-ishover={data.isHover}
    >
      <Icon1 fontSize="medium" component={props.sidebarItem.icon} />
      {!(!props.sidebarHover && !props.sSelected) &&
        !(!props.sidebarHover && props.isSelected) ? (
        <Text className="text">{props.sidebarItem.text}</Text>
      ) : null}
    </div>
  );
}

export default SideBarRow;
