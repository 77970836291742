import React, { useState, useEffect } from 'react';
import './JointModal.css';
import { useNavigate } from "react-router-dom";

import LeftKneeImage from "../../assets/images/left-knee-illustration.png";
import RightKneeImage from "../../assets/images/right-knee-illustration.png";
import LeftAnkleImage from "../../assets/images/left-ankle-illustration.png";
import RightAnkleImage from "../../assets/images/right-ankle-illustration.png";
import LeftElbowImage from "../../assets/images/left-elbow-illustration.png";
import RightElbowImage from "../../assets/images/right-elbow-illustration.png";
import LeftHipImage from "../../assets/images/left-hip-illustration.png";
import RightHipImage from "../../assets/images/right-hip-illustration.png";
import LeftShoulderImage from "../../assets/images/left-shoulder-illustration.png";
import RightShoulderImage from "../../assets/images/right-shoulder-illustration.png";
import NeckImage from "../../assets/images/neck-illustration.png";

const JointModal = ({ jointName, onClose, side }) => {
    const modalClass = side === 'left' ? 'modal-left' : 'modal-right';
    const formattedJointName = jointName.replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());

    const [exercises, setExercises] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const data = {
            "left-elbow": {
                "exercises": [
                    "Elbow Flexion and Extension",
                    "Wrist Flexion and Extension",
                    "Pronation and Supination"
                ]
            },
            "left-shoulder": {
                "exercises": ["Minor Rotator Cuff Repair", "Major Rotator Cuff Repair"]
            },
            "left-knee": {
                "exercises": [
                    "Lunge Walking",
                    "Straight Leg Raise",
                    "Terminal Knee Extension",
                    "Quadriceps Sets"
                ]
            },
            "left-ankle": {
                "exercises": [
                    "Ankle Dorsiflexion Self Mobilization",
                    "Ankle Plantarflexion Self Mobilization",
                    "Ankle Circles",
                    "Toe Tapping"
                ]
            },
            "right-elbow": {
                "exercises": [
                    "Elbow Flexion and Extension",
                    "Wrist Flexion and Extension",
                    "Pronation and Supination"
                ]
            },
            "right-shoulder": {
                "exercises": ["Minor Rotator Cuff Repair", "Major Rotator Cuff Repair"]
            },
            "right-knee": {
                "exercises": [
                    "Lunge Walking",
                    "Straight Leg Raise",
                    "Terminal Knee Extension",
                    "Quadriceps Sets"
                ]
            },
            "right-ankle": {
                "exercises": [
                    "Ankle Dorsiflexion Self Mobilization",
                    "Ankle Plantarflexion Self Mobilization",
                    "Ankle Circles",
                    "Toe Tapping"
                ]
            },
            "neck": {
                "exercises": [
                    "Neck Retraction and Extension",
                    "Neck Side Stretch",
                    "Shoulder Shrugs"
                ]
            },
            "lower-back": {
                "exercises": ["Cat Back Stretches", "Clam Shell Exercise"]
            },
            "left-hip": {
                "exercises": [
                    "Hip Flexor Stretch",
                    "Piriformis Stretch",
                    "Hip Abduction Exercise"
                ]
            },
            "right-hip": {
                "exercises": [
                    "Hip Flexor Stretch",
                    "Piriformis Stretch",
                    "Hip Abduction Exercise"
                ]
            }
        };

        if (data[jointName] && data[jointName].exercises) {
            setExercises(data[jointName].exercises);
        }
    }, [jointName]);

    const generateExerciseUrl = (exerciseName) => {
        return exerciseName.toLowerCase().replace(/\s+/g, '_');
    };

    const handleExerciseClick = (exerciseName) => {
        const exerciseUrl = generateExerciseUrl(exerciseName);
        const newUrl = `/${exerciseUrl}`;
        window.history.replaceState(null, '', newUrl);
        navigate(newUrl, { replace: true });
    };

    const images = {
        'left-elbow': LeftElbowImage,
        'left-shoulder': LeftShoulderImage,
        'left-knee': RightKneeImage,
        'left-ankle': LeftAnkleImage,
        'left-hip': LeftHipImage,
        'right-elbow': RightElbowImage,
        'right-shoulder': RightShoulderImage,
        'right-knee': LeftKneeImage,
        'right-ankle': RightAnkleImage,
        'right-hip': RightHipImage,
        'neck': NeckImage,
        'lower-back': NeckImage,
    };

    return (
        <div className={`modal-joints ${modalClass}`}>
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <div className='joint-upper-div'>
                    <img src={images[jointName]} className="joint-image" alt={formattedJointName} />
                    <div className='joint-name'>{formattedJointName}</div>
                </div>
                <div className='joint-exercise'>
                    {exercises.map((exercise, index) => (
                        <div className='joint-exercise-name' key={index} onClick={() => handleExerciseClick(exercise)}>
                            {exercise}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default JointModal;
