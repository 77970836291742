import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Cell,
} from 'recharts';
import './ExerciseProgress.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const ExerciseProgress = ({ isOpen, onRequestClose, exerciseType }) => {
    const [jsonData, setJsonData] = useState(null);
    const [animationStarted, setAnimationStarted] = useState(false);
    const animationDuration = 10000;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('languagePreference') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    useEffect(() => {
        const username = localStorage.getItem('username');

        const startAnimationTimeout = setTimeout(() => {
            setAnimationStarted(true);
        }, 500);

        fetch('/combined_frames_data')
            .then(response => response.json())
            .then(data => {
                if (data[username]) {
                    setJsonData(data[username]);
                } else {
                    console.warn(`No data found for username: ${username}`);
                }
            })
            .catch(error => console.error('Error fetching data:', error));

        return () => clearTimeout(startAnimationTimeout);
    }, []);

    const handleOverlayClick = (e) => {
        onRequestClose();
    };

    const handleModalClick = (e) => {
        e.stopPropagation();
    };

    if (!jsonData) {
        return <div>Loading...</div>;
    }

    let exerciseData = null;
    let exerciseName = '';
    switch (exerciseType) {
        case 'standingwallslide':
            exerciseData = jsonData['standingwallslide'];
            exerciseName = 'standingwallslide';
            break;
        case 'standingextension':
            exerciseData = jsonData['standingextension'];
            exerciseName = 'standingextension';
            break;
        case 'flexiononawall':
            exerciseData = jsonData['flexiononawall'];
            exerciseName = 'flexiononawall';
            break;
        case 'quadrupedrockback':
            exerciseData = jsonData['quadrupedrockback'];
            exerciseName = 'quadrupedrockback';
            break;
        case 'squat':
            exerciseData = jsonData['squat'];
            exerciseName = 'squat';
            break;
        case 'standingkneeflexion':
            exerciseData = jsonData['standingkneeflexion'];
            exerciseName = 'standingkneeflexion';
            break;
        default:
            break;
    }

    if (!exerciseData) {
        return <div>No data available for this exercise type.</div>;
    }

    const { totalaccuracy, repaccuracy } = exerciseData;
    const roundedAccuracy = Math.round(totalaccuracy);
    const textSize = 50;

    return (
        <>
            {isOpen && (
                <div className='MainDivModal' onClick={handleOverlayClick}>
                    <div className='ProgressBarDivExercise' onClick={handleModalClick}>

                        <div key={0} className="progress-bar-container">
                            <div className="modal-header">
                                <button className="close-button" onClick={onRequestClose}>✖</button>
                            </div>
                            <div className="progress-bar-container-two">
                                <h1>{t(exerciseName)}</h1>
                            </div>
                            <div className="progress-bar-container-one">
                                {/* Line Chart for rep accuracy */}
                                <div className="line-chart-container">
                                    <ResponsiveContainer height={300}>
                                        <BarChart data={repaccuracy} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                            <XAxis dataKey="reps" label={{ value: `${t("repsgraph")} `, position: 'insideBottom', dy: 7 }} />
                                            <YAxis label={{ value: `${t("accuracy")} (%)`, angle: -90, position: 'insideLeft' }} />
                                            <Tooltip />
                                            <Bar dataKey="accuracy" name={`${t("accuracy")}`} fill="#3CB371" >
                                                {
                                                    repaccuracy.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={entry.accuracy > 50 ? '#3BB371' : '#E06666'} />
                                                    ))
                                                }
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>

                                <div className='progressDiv'>
                                    <CircularProgressbar
                                        value={animationStarted ? roundedAccuracy : 0}
                                        text={`${t("accuracy")} ${roundedAccuracy}%`}
                                        styles={buildStyles({
                                            textColor: '#1a1a1a',
                                            pathColor: '#1384D6',
                                            trailColor: '#d6d6d6',
                                            textSize: `${textSize}%`,
                                        })}
                                        className="CircularProgressbar"
                                        initialAnimation={false}
                                        animate={animationStarted}
                                        duration={animationDuration}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="dashboard-button-container">
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    fontFamily: "'Montserrat', sans-serif",
                                    fontWeight: 700,
                                }}
                                onClick={() => {
                                    navigate('/Dashboard');
                                }}
                            >
                                {t('dashboard')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ExerciseProgress;
