import './contact.css';
import { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import ReactGA from 'react-ga4';

const Contact = () => {
    const formRef = useRef();
    const [file, setFile] = useState(null); 
    const [name, setName] = useState(""); 
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState(""); 
    const [showPopup, setShowPopup] = useState(false); 
    const [done, setDone] = useState(false)

    // const handleFileChange = (e) => {
    //     const selectedFile = e.target.files[0];
    //     setFile(selectedFile);
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        ReactGA.event({
            category: 'Form',
            action: 'Submit',
            label: 'Contact Form Submission'
        });

        emailjs
        .sendForm(
          "service_241a5kq",
          "template_yb83pig",
          formRef.current,
          "5Ga8uKjz4P_65T7AS"
        )
        .then(
          (result) => {
            console.log(result.text);
            setDone(true)
          },
          (error) => {
            console.log(error.text);
          }
        );

        if (!name || !email) {
            alert("Name and Email are required fields.");
            return;
        }

        console.log("Selected File:", file);

        formRef.current.reset();
        setFile(null);
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setShowPopup(true); 
    };

    return (
        <section id="contact">
            <div className="c-heading">
                Let's Connect

                <form ref={formRef} className='c-form' onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Name"
                        className="c-form-inputs"
                        value={name}
                        name="user_name"
                        onChange={(e) => setName(e.target.value)}
                        required 
                    />
                    <input
                        type="email"
                        placeholder="Email"
                        className="c-form-inputs"
                        value={email}
                        name="user_email"
                        onChange={(e) => setEmail(e.target.value)}
                        required 
                    />
                    <input
                        type="text"
                        placeholder="Subject"
                        className="c-form-inputs"
                        value={subject}
                        name="user_subject"
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <textarea
                        rows="5"
                        placeholder="Message"
                        className="c-form-message"
                        value={message}
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    {/* <input className="file-button" type="file" onChange={handleFileChange} /> */}
                    <button type="submit">Submit</button>
                    {done}
                </form>

                {showPopup && (
                    <div className="popup">
                        <p>Form submitted successfully!</p>
                        <button onClick={() => setShowPopup(false)}>Close</button>
                    </div>
                )}
            </div>
        </section>
    );
}

export default Contact;
