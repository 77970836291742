import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import Reaction from "./reaction/Reaction";
// import useFeedbackBar from "./UseFeedbackBar";
import "./FeedbackBar.css";


function FeedbackBar() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('languagePreference') || 'en';
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  return (
    <div className="FeedbackBar1">
      {t('greeting')}
    </div>
  );
}

export default FeedbackBar;
