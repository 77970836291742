import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DocNavBar from "./docNavbar/Docnavbar";
import DocFeedbackBar from "./docFeedbackBar/docFeedbackBar";
import Upcoming from "../upcoming/Upcoming";
import WhatsAppChat from "../whatsappchat/WhatsAppChat";
import Avatar_1 from "../assets/images/Avatar_1.png";
import Avatar_2 from "../assets/images/Avatar_2.png";
import Avatar_3 from "../assets/images/Avatar_3.png";
import PatientCard from "./patientcard/patientCard";
import "./doctorsPage.css";
import { Button } from "@mui/material";


const DoctorsPage = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const savedLanguage = localStorage.getItem('languagePreference') || 'en';
        i18n.changeLanguage(savedLanguage);
    }, [i18n]);

    return (
        <div className="TypeQuestSidebarOpen">
            <div className="Page">
                <div className="ContentDoctor">
                    <div className="Sec1">
                        <DocNavBar />
                        <div className="HeroContent">
                        </div>
                        <DocFeedbackBar className="FeedbackBar1" />
                    </div>
                    <div className="Sec3-doc">
                        <div className="Sec3-subset">
                            <div className="patientsList">Patients List</div>
                            <div className="add-patient">
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="info"
                                    style={{
                                        fontFamily: "'Montserrat', sans-serif",
                                        fontWeight: 550,
                                        textTransform: 'none'
                                    }}
                                >
                                    {'Add New Patient'}
                                </Button>
                            </div>
                        </div>
                        <div className="Items-doc">
                            <PatientCard
                                itemName={'Arsh Guleria'}
                                itemDetails={'Clinical condition: Frozen Shoulder'}
                                navigationPath="patient"
                                imageUrl={Avatar_1}
                            />
                            <PatientCard
                                itemName={'Mamta Patel'}
                                itemDetails={'Clinical condition: Knee Ligament Injury'}
                                videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                                navigationPath="patient"
                                imageUrl={Avatar_2}
                            />
                            <PatientCard
                                itemName={'S.Michael'}
                                itemDetails={'Clinical condition: Ankle sprain'}
                                videoId="b7yQwf-K7z0?si=vlf2HGMG-s6oS7_N"
                                navigationPath="patient"
                                imageUrl={Avatar_3}
                            />
                        </div>
                    </div>
                    <div className="Sec2">
                        {/* <Upcoming />
                        <WhatsAppChat /> */}
                    </div>
                    <div className="Sec4"></div>
                </div>
            </div>
        </div >
    );
}

export default DoctorsPage;
