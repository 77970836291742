import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';

const CameraCapture = () => {
    const webcamRef = useRef(null);
    const [grayImage, setGrayImage] = useState('');

    useEffect(() => {
        const intervalId = setInterval(capture, 100); // Adjust the interval as needed (e.g., every second)

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);

    const capture = async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        const blob = await fetch(imageSrc).then((res) => res.blob());

        const formData = new FormData();
        formData.append('image', blob);

        axios.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                setGrayImage(response.data.gray_img);
            })
            .catch((error) => {
                console.error('Error uploading image:', error);
            });
    };

    return (
        <>
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                    facingMode: 'user' // Use 'user' for front camera, 'environment' for rear camera
                }}
            />
            {grayImage && <img src={`data:image/jpeg;base64,${grayImage}`} alt="Grayscale Webcam" />}
        </>
    );
};

export default CameraCapture;
