import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
    .use(LanguageDetector).use(initReactI18next).init({
        debug: true,
        lng: "en",
        fallbackLng: "en",
        returnObjects: true,
        resources: {
            en: {
                translation: {
                    greeting: "Welcome back to neonimble.ai",
                    enrollementdate: "Enrollment Date",
                    doctorallocated: "Doctor Allocated",
                    languagepreference: "Language Preference",
                    name: "Test User",
                    doctorname: "Dr. Test",
                    month: "November",
                    dashboard: "Dashboard",
                    progressreport: "Progress Report",
                    account: "Account",
                    help: "Help",
                    whatsapp: "Click here to Chat with your Doctor on WhatsApp",
                    startchat: "Start Chat",
                    doctornote: "Doctor's Note",
                    yesterday: "Yesterday",
                    today: "Today",
                    yesterdaynote: "Incorporated proprioception exercises to enhance balance and stability.Will continue to monitor progress closely and adjust treatment plan as needed.",
                    todaynote: "Introduced gentle range of motion exercises to improve shoulder mobility. Observed some improvement in flexibility and reduced stiffness.",
                    reps: "Reps",
                    todaysgoal: "Today's Goal",
                    squat: "Squats",
                    bicepcurls: "Bicep Curls",
                    sidebend: "Side Bend (Pilates)",
                    start: "Start",
                    demo: "Demo",
                    goback: "Go Back",
                    startexercise: "Start Exercise",
                    progress: "Progress",
                    nextexercise: "Next Exercise",
                    accuracy: "Accuracy",
                    repsgraph: "Reps",
                    standingwallslide: "Standing Wall Slide",
                    standingextension: "Standing Extension",
                    flexiononawall: "Flexion on a wall",
                    quadrupedrockback: "Quadruped Rock Back",
                    logout: "Log Out",
                    squats: "Squats",
                    standingkneeflexion: "Standing Knee Flexion",
                },
            },
            hi: {
                translation: {
                    greeting: "अर्श, neonimble.ai में आपका पुनः स्वागत है",
                    enrollementdate: "नामांकन की तिथि",
                    doctorallocated: "डॉक्टर आवंटित",
                    languagepreference: "भाषा प्राथमिकता",
                    name: "अर्श गुलेरिया",
                    doctorname: "डॉ. कप शिवरतना",
                    month: "नवंबर",
                    dashboard: "डैशबोर्ड",
                    progressreport: "प्रगति रिपोर्ट",
                    account: "विवरण",
                    help: "मदद",
                    whatsapp: "व्हाट्सएप पर अपने डॉक्टर से बात करने के लिए यहां दबाएं",
                    startchat: "बात करें",
                    doctornote: "डॉक्टर की टिप्पणी",
                    yesterday: "पिछले कल",
                    today: "आज",
                    yesterdaynote: "बैलेंस और स्थिरता को बढ़ाने के लिए संचित उत्तोलन व्यायाम को शामिल किया। प्रगति को निकाला गया है और चिकित्सा योजना को बीतनुसार समीक्षित किया जाएगा।",
                    todaynote: "कंधों की चरम गति में सुधार के लिए हल्के रेंज ऑफ मोशन व्यायाम को प्रस्तुत किया। प्रभावित क्षेत्रों में लचीलाई में कुछ सुधार देखा।",
                    reps: "बार दोहराना",
                    todaysgoal: "आज का लक्ष्य",
                    squats: "दंड-बैठक",
                    bicepcurls: "बाइसेप कर्ल्स",
                    sidebend: "साइड बेंड (पिलाटीस)",
                    start: "शुरू करें",
                    demo: "प्रतिदर्श",
                    goback: "वापस जाएं",
                    startexercise: "शुरू करें",
                    progress: "प्रगति देखें",
                    nextexercise: "अगले पर जाएँ",
                    accuracy: "सटीकता",
                    repsgraph: "पुनरावृत्ति",
                    standingwallslide: "खड़े होकर दीवार के साथ स्लाइड",
                    standingextension: "कंधों का विस्तार",
                    flexiononawall: "दीवार पर मोड़",
                    logout: "लॉग आउट",
                },
            },
            gu: {
                translation: {
                    greeting: "neonimble.ai પર ફરીથી આપનું સ્વાગત છે, અર્શ",
                    enrollementdate: "નોંધણી તારીખ",
                    doctorallocated: "ડૉક્ટરનું નામ",
                    languagepreference: "ભાષા પસંદગી",
                    name: "અર્શ ગુલેરિયા",
                    doctorname: "ડૉ. કેપી શિવરતના",
                    month: "નવેમ્બર",
                    dashboard: "ડેશબોર્ડ",
                    progressreport: "પ્રગતિ રિપોર્ટ",
                    account: "એકાઉન્ટ",
                    help: "મદદ",
                    whatsapp: "આપના ડૉક્ટર સાથે વોટ્સએપ પર ચેટ કરવા માટે અહીં ક્લિક કરો",
                    startchat: "ચેટ શરૂ કરો",
                    doctornote: "ડોક્ટરની નોંધ",
                    yesterday: "ગઈકાલી",
                    today: "આજે",
                    yesterdaynote: "સંતુળન અને સ્થિરતા વધારવા માટે વિશેષ ઉત્તોલન વ્યાયામ સમાવિષ્ટ કર્યો. પ્રગતિને મૂલ્યાંકન કરવા માટે સારવી આ ચિકિત્સા યોજના અનુસાર સ્થિતિનું સુધાર કરવામાં આવશે.",
                    todaynote: "કંધના ગતિમાનમાં સુધાર માટે સ્વલા અંદાજ કરવા માટે મિટી રેન્જ ઓફ મોશન વ્યાયામ પરિચિત કર્યો. પ્રભાવિત ક્ષેત્રોમાં લાચિલાઈની કેટલીક ઘટણા થઈ.",
                    reps: "વારતા",
                    todaysgoal: "આજનો લક્ષ્ય",
                    squats: "સ્ક્વોટ્સ",
                    bicepcurls: "બાઇસેપ કર્લ્સ",
                    sidebend: "સાઇડ બેન્ડ (પિલાટીસ)",
                    start: "શરૂ કરો",
                    demo: "ડેમો",
                    goback: "પાછા જાઓ",
                    startexercise: "અભ્યાસ શરૂ કરો",
                    progress: "પ્રગતિ",
                    nextexercise: "આગળનો અભ્યાસ",
                    accuracy: "સાચાઈ",
                    repsgraph: "પુનરાવૃત્તિ",
                    standingwallslide: "ખડકને વોરા સાથે સ્લાઇડ",
                    standingextension: "ખડકને શોલ્ડર એક્સ્ટેન્શન",
                    flexiononawall: "વાળ પર ફ્લેક્શન",
                    logout: "લૉગ આઉટ",
                },
            },
            ma: {
                translation: {
                    greeting: "neonimble.ai पर परत स्वागत आहे, अर्श",
                    enrollementdate: "नोंदणीची तारीख",
                    doctorallocated: "डॉक्टर नियुक्त",
                    languagepreference: "भाषा प्राधान्य",
                    name: "अर्श गुलेरिया",
                    doctorname: "डॉ. के.पी. शिवरत्ना",
                    month: "नोव्हेंबर",
                    dashboard: "डॅशबोर्ड",
                    progressreport: "प्रगती अहवाल",
                    account: "खाते",
                    help: "मदत",
                    whatsapp: "आपल्या डॉक्टरसोबत WhatsApp वर चॅट करण्यासाठी येथे क्लिक करा",
                    startchat: "चॅट सुरू करा",
                    doctornote: "डॉक्टरचा टिप्पणी",
                    yesterday: "काल",
                    today: "आज",
                    yesterdaynote: "संतुलन आणि स्थिरतेसाठी विशेष उत्तोलन व्यायामे समाविष्ट केली. संभावना आहे की कसरती शक्तीसाठ.",
                    todaynote: "कंध्याच्या वाचवण्यासाठी लवकरची गती व्यायामे परिचय दिली. प्रभावित क्षेत्रांमध्ये काही सुधार दिसले.",
                    reps: "प्रतियोगिता",
                    todaysgoal: "आजचा उद्दिष्ट",
                    squats: "बैठक",
                    bicepcurls: "बायसेप कर्ल्स",
                    sidebend: "साइड बेन्ड (पिलेट्स)",
                    start: "सुरू करा",
                    demo: "डेमो",
                    goback: "परत जा",
                    startexercise: "अभ्यास सुरू करा",
                    progress: "प्रगती",
                    nextexercise: "पुढील अभ्यास",
                    accuracy: "सटीकता",
                    repsgraph: "पुनरावृत्ती",
                    standingwallslide: "उभे दीवार वर व्हिचत दुडकणे",
                    standingextension: "उभे कामावर शोल्डर एक्सटेन्शन",
                    flexiononawall: "दीवारवर उत्तोलन",
                    logout: "लॉग आऊट",
                },
            },
        },
    });
