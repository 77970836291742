import "./footer.css";

const Footer = ()=>{
    return(
        <div className="f">
            <div className="f-left">© 2023 neonimble.ai All rights reserved</div>
            {/* <div className="f-right">
                <div className="f-right-content">sheetal@neonimble.ai</div>
            </div> */}
        </div>
    );
};

export default Footer;